.footerMainDiv{
    background: #EAEEEE;
    display: flex;
    justify-content: space-between;
    width: 80%;
    padding: 50px 0;
    margin: auto;
    align-items: center;
}

.footerTag{
    display: flex;
    flex-direction: column;
    text-align: start;
}

@media(max-width:480px){
    .footerMainDiv{
        background: #EAEEEE;
        display: flex;
        justify-content: space-between;
        width: 85%;
        padding: 50px 0;
        margin: auto;
        align-items: center;
    }
    
}