
.navbar-collapse{
  justify-content: end !important;
}

@media (min-width:992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    /* padding-right: var(--bs-navbar-nav-link-padding-x); */
    /* padding-left: var(--bs-navbar-nav-link-padding-x); */
    padding: 0 30px;
}
.navbar-expand-lg .navbar-nav {
  flex-direction: row;
  align-items: center;
}
.navbarContainer{
  width: 80%;
}
}

.loginBtn{
  background-color: blue;
  color: white !important;
  padding: 10px 20px !important;
  border-radius: 8px;
  margin: 0 0 0 30px;
  
}

.navbar{
  background-color: white !important;
}

.navButton {
  color: #006BCD;
  text-align: center;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  font-family: 'Open Sans', sans-serif;
    font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media(max-width:500px){
  .loginBtn {
    background-color: blue;
    color: white !important;
    padding: 10px 20px !important;
    border-radius: 8px;
    margin: 10px 30px;
}
.logoImageNav{
  width: 22%;
}
.navLogin{
  display: block;
  margin: auto;
}
}