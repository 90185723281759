
.sliderCon{
    /* background-image: url('../img//Home/redcar.png'); */
    /* background-image: url('../img//Home/red\ car-responsive.png'); */
    background-repeat: no-repeat;
    width: 100vw;
    height: auto;
    position: relative;
    padding: 0;
    background-size: cover;
}
.bannerMain2{
    display: none;
}
 body::-webkit-scrollbar {
    display:none;
}
@media(max-width:500px){
    .bannerMain{
        display: none;
    }
    .bannerMain2{
        display: block;
    }
    .sliderCon2{
        /* background-image: url('../img//Home/redcar.png'); */
        /* background-image: url('../img//Home/red\ car-responsive.png'); */
        background-repeat: no-repeat;
        width: 100vw;
        height: 100%;
        position: relative;
        padding: 0;
        background-size: cover;
    }
}
@media(min-width:501px) and (max-width:1250px){
    .sliderCon{
        height: 36vh;
    }
    .aboutnImg {
        position: absolute;
        right: 2% !important;
        width: 360px !important;
        margin-top: -45px !important;
    }
}