.contentHeader {
    color: #006BCD;
    text-align: justify;
    font-family: 'Open Sans', sans-serif;
        font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.contentHeader2{
    color: #006BCD;
    font-family: 'Open Sans', sans-serif;
    font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.aboutContent{
    margin: 50px auto;
    width: 80%;
}

.contentPara{
    text-align: justify;
    font-weight: 500;
    width: 70%;
    font-family: 'Open Sans', sans-serif;
        font-size: 16px;
}

.aboutnImg{
    width: 400px;
    position: absolute;
    right: 10%;
}
.aboutMain{
    height: 400px;
}

@media(max-width:480px){
    .aboutContent {
        margin: 42px auto 20px;
        width: 85%;
    }
    
    .contentHeader {
        color: #006BCD;
        text-align: justify;
        font-family: 'Open Sans', sans-serif;
                font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 25px;
    }
    
    .contentHeader2 {
        color: #006BCD;
        font-family: 'Open Sans', sans-serif;
                font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .contentPara {
        text-align: justify;
        font-weight: 500;
        width: 100%;
    }
    
    .aboutnImg {
        width: 250px;
        position: absolute;
        right: 15px;
    }
    
    .aboutMain {
        height: 500px;
    }
}

@media (min-width:1400px){
    .aboutMain{
        height: 380px;
    }
}