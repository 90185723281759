.linkwebsiteContent{
    height: auto;
    padding: 60px 0;
    background-color: #006BCD;
}

.tryOurFree{
    color: #FFF;
text-align: start;
font-family: 'Open Sans', sans-serif;
font-size: 48px;
font-style: normal;
font-weight: 400;
/* line-height: normal; */
}
.tryOurFree2{
    color: #FFF;
    font-family: 'Open Sans', sans-serif;
    font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.lintConent{
    width: 80%;
    margin: auto;
}

.lingImage{
width: 150px;
}

@media(max-width:480px){
    .lingImage {
        width: 86px;
    }
    
    .tryOurFree2 {
        color: #FFF;
        font-family: Barlow;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    
    .tryOurFree {
        color: #FFF;
        text-align: start;
        font-family: Barlow;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        /* line-height: normal; */
    }
    
    .lintConent {
        width: 85%;
        margin: auto;
    }
}