.contentMiddle{
    background-color: #006BCD;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px 0;
}

.contentImg{
    padding: 30px;
}

@media(max-width:480px){
    .contentMiddle {
        background-color: #006BCD;
        display: block;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 20px 0;
    }
    
    .contentImg {
        padding: 30px 0;
        width: 65%;
        margin: auto;
    }
}